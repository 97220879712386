import store from '@/store'

import {ref} from '@vue/composition-api'

export default function templateList() {
    const data = ref({
        content: "123",
        snack: {
            show: false,
            msg: ''
        },
    })
    const tinymceSetting = {
        menubar: false,
        toolbar: "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
        toolbar_drawer: "sliding",
        quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
        plugins: "link table lists fullscreen quickbars",
        language: 'zh_CN', //本地化设置
        height: 700
    }
    const fetchRule = () => {
        store.dispatch('content-rule/fetchContentInfo', "RULE").then(res => {
            const {code, msg} = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                data.value.snack = {
                    show: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            data.value.content = res.data.data.content
        }).catch(error => {
            console.log(error)
        }).finally(() => {

            }
        )
    }
    const updateRule = () => {
        store.dispatch('content-rule/updateContentInfo', {type: "RULE", content: data.value.content}).then(res => {
            const {code, msg} = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                data.value.snack = {
                    show: true,
                    msg: `错误码：${code}, ${msg}`
                }
            }else{
                data.value.snack = {
                    show: true,
                    msg: `保存成功`
                }
            }
        }).catch(error => {
            console.log(error)
        }).finally()
    }
    return {
        data,
        tinymceSetting,
        fetchRule,
        updateRule,
    }
}
